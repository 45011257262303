<template>
  <div class="about">
    <el-row :gutter="10">
      <el-col :span="4">
        <el-card style="height: 100%">
          <el-tree
              ref="treeMenu"
              :data="ruleList"
              :expand-on-click-node="false"
              :default-expand-all="isOpen"
              node-key="id"
              @node-click="search"
              :check-on-click-node="true"
              highlight-current
              :props="defaultProps">
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="20">
        <div>
          <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
          <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
        </div>
        <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
          <el-table-column  align="center"
                            type="index"
                            width="55" label="#">
          </el-table-column>
          <el-table-column width="100" prop="name" label="头像" >
            <template #default="scope">
              <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                <el-image
                    v-if="scope.row.profile_url"
                    style="width: 50px; height: 50px"
                    :src="scope.row.profile_url"
                    :preview-src-list="[scope.row.profile_url]">
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="nickname" width="130" label="用户名称">
          </el-table-column>
          <el-table-column prop="group_name" show-overflow-tooltip width="90" label="部门">
            <template #default="scope">
              <div v-if="scope.row.group_name">
                <el-tag type="success" size="mini">{{ scope.row.group_name }}</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="100" prop="is_of_us" label="是否直选" >
            <template #default="scope">
              <div v-if="scope.row.is_of_us==1">
                <el-tag type="warning" size="mini">直选师傅</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="real_name" label="真实姓名">
            <template #default="scope">
              <el-tag size="mini" v-if="scope.row.real_name">{{scope.row.real_name}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column width="124" prop="mobile_phone" label="手机号" >
          </el-table-column>
          <el-table-column width="180" prop="idcard" label="身份证号" >
          </el-table-column>
          <el-table-column width="100" prop="name" label="身份证正面" >
            <template #default="scope">
              <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                <el-image
                    v-if="scope.row.identify_z"
                    style="width: 50px; height: 50px"
                    :src="scope.row.identify_z"
                    :preview-src-list="[scope.row.identify_z]">
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="110" prop="name" label="身份证国徽面" >
            <template #default="scope">
              <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                <el-image
                    v-if="scope.row.identify_f"
                    style="width: 50px; height: 50px"
                    :src="scope.row.identify_f"
                    :preview-src-list="[scope.row.identify_f]">
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="100" prop="name" label="营业执照" >
            <template #default="scope">
              <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                <el-image
                    v-if="scope.row.business"
                    style="width: 50px; height: 50px"
                    :src="scope.row.business"
                    :preview-src-list="[scope.row.business]">
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="100" prop="name" label="人脸照片" >
            <template #default="scope">
              <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                <el-image
                    v-if="scope.row.face"
                    style="width: 60px; height: 60px"
                    :src="scope.row.face"
                    :preview-src-list="[scope.row.face]">
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column  width="70" prop="order_count" label="接单数">
            <template #default="scope">
             <el-tag size="mini">{{scope.row.order_count}}次</el-tag>
            </template>
          </el-table-column>
          <el-table-column  width="80" prop="level" label="师傅等级">
            <template #default="scope">
              <el-tag size="mini">{{scope.row.level}}级</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="area_name" label="服务地区" >
          </el-table-column>
          <el-table-column prop="create_time" label="师傅状态" >
            <template #default="scope">
              <div v-if="scope.row.status==0">
                新注册
              </div>
              <div v-else-if="scope.row.status==1">
                已入驻
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" width="160" label="师傅注册时间">
          </el-table-column>
          <el-table-column fixed="right" prop="address" label="操作" width="260">
            <template #default="scope">
              <div style="margin: 5px 0">
                <el-button-group>
                  <el-button size="mini" type="primary"  @click="upData(scope.row)">查看详情</el-button>
                  <el-button size="mini" type="primary"  @click="upOrder(scope.row,1)">接单数</el-button>
                  <el-button size="mini" type="primary"  @click="upOrder(scope.row,2)">等级</el-button>
                  <el-button size="mini" type="primary"  @click="upZu(scope.row)">部门</el-button>
                  <el-button size="mini" v-if="scope.row.is_of_us==0" type="primary"  @click="onCashList(scope.row,1)">师傅直选</el-button>
                  <el-button size="mini" v-else-if="scope.row.is_of_us==1" type="primary"  @click="onCashList(scope.row,0)">取消直选</el-button>
                </el-button-group>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
          <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-sizes="[10, 20, 30, 40,50]"
              :page-size="limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>

        </div>
        <el-drawer
            title="详情"
            v-model="drawer"
            size="60%"
            direction="rtl"
            :close-on-click-modal="false"
            destroy-on-close>
          <el-scrollbar height="calc(100vh - 160px)" style="padding:0 15px;height:calc(100% - 30px) ">
            <el-descriptions  :column="3" border :title="ruleFormcont.nickname">
              <el-descriptions-item label="头像">
                <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                  <el-image
                      v-if="ruleFormcont.profile_url"
                      style="width: 50px; height: 50px"
                      :src="ruleFormcont.profile_url"
                      :preview-src-list="[ruleFormcont.profile_url]"
                  >
                  </el-image>
                </div>
              </el-descriptions-item>
              <el-descriptions-item minwidth="120" label="真实姓名:"><el-tag size="mini" v-if="ruleFormcont.real_name">{{ruleFormcont.real_name}}</el-tag></el-descriptions-item>
              <el-descriptions-item label="性别:">
                <span>{{ruleFormcont.idcard_sex}}</span>
              </el-descriptions-item>
              <el-descriptions-item label="师傅手机号:">{{ruleFormcont.mobile_phone}}</el-descriptions-item>
              <el-descriptions-item label="身份证号码:"><el-tag size="mini" v-if="ruleFormcont.idcard">{{ruleFormcont.idcard}}</el-tag></el-descriptions-item>
              <el-descriptions-item label="状态:">
                <span v-if="ruleFormcont.status==0">新注册</span>
                <span v-else>已入驻</span>
              </el-descriptions-item>
              <el-descriptions-item label="注册时间:">
                <span>{{ruleFormcont.create_time}}</span>
              </el-descriptions-item>
              <el-descriptions-item label="身份证正面">
                <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                  <el-image
                      v-if="ruleFormcont.identify_z"
                      style="width: 50px; height: 50px"
                      :src="ruleFormcont.identify_z"
                      :preview-src-list="[ruleFormcont.identify_z]"
                  >
                  </el-image>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="身份证国徽面">
                <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                  <el-image
                      v-if="ruleFormcont.identify_f"
                      style="width: 50px; height: 50px"
                      :src="ruleFormcont.identify_f"
                      :preview-src-list="[ruleFormcont.identify_f]"
                  >
                  </el-image>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="营业执照">
                <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                  <el-image
                      v-if="ruleFormcont.business"
                      style="width: 50px; height: 50px"
                      :src="ruleFormcont.business"
                      :preview-src-list="[ruleFormcont.business]"
                  >
                  </el-image>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="人脸照片">
                <div style="height: 100%;display: flex;align-items:center;padding: 5px 0">
                  <el-image
                      v-if="ruleFormcont.face"
                      style="width: 50px; height: 50px"
                      :src="ruleFormcont.face"
                      :preview-src-list="[ruleFormcont.face]"
                  >
                  </el-image>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="积分:">{{ruleFormcont.points}}</el-descriptions-item>
              <el-descriptions-item label="服务介绍:">{{ruleFormcont.short_content}}</el-descriptions-item>
              <el-descriptions-item label="等级:">{{ruleFormcont.level}}</el-descriptions-item>
              <el-descriptions-item label="服务市区:">{{ruleFormcont.area_id}}</el-descriptions-item>
              <el-descriptions-item label="详细地址:">{{ruleFormcont.address}}</el-descriptions-item>
              <el-descriptions-item label="工作日:">周{{ruleFormcont.week_from}}--周{{ruleFormcont.week_to}}</el-descriptions-item>
              <el-descriptions-item label="上班时间:">{{ruleFormcont.time_from}}--{{ruleFormcont.time_to}}</el-descriptions-item>
              <el-descriptions-item label="团队人数:">{{ruleFormcont.team_size}}</el-descriptions-item>
              <el-descriptions-item label="货车数量:">{{ruleFormcont.truck_count}}</el-descriptions-item>
              <el-descriptions-item label="货车大小:">
                <span v-if="ruleFormcont.status==0">小型</span>
                <span v-else-if="ruleFormcont.status==1">中型</span>
                <span v-else-if="ruleFormcont.status==2">大型</span>
              </el-descriptions-item>
              <el-descriptions-item label="备用手机号:">{{ruleFormcont.second_phone}}</el-descriptions-item>
              <el-descriptions-item label="服务质量分:">{{ruleFormcont.score}}</el-descriptions-item>
              <el-descriptions-item label="订单量:">{{ruleFormcont.order_count}}</el-descriptions-item>
              <el-descriptions-item label="是通过实名认证:">
                <span v-if="ruleFormcont.status==0">是</span>
                <span v-else-if="ruleFormcont.status==1">否</span>
              </el-descriptions-item>
            </el-descriptions>
            <el-descriptions  :column="1" border title="">
              <el-descriptions-item :span="1" label="师傅银行卡:">
                <!--          {{ruleFormcont.worker_bank}}-->
                <el-tabs  v-model="bankActive">
                  <el-tab-pane v-for="(item,i) in ruleFormcont.worker_bank" :key="i" :label="'银行卡【'+(parseInt(i)+1)+'】'" :name="String((parseInt(i)+1))">
                    <el-descriptions :span="1" >
                      <el-descriptions-item label="类型:">{{item.card_type}}</el-descriptions-item>
                      <el-descriptions-item label="银行:">{{item.bank_name}}</el-descriptions-item>
                      <el-descriptions-item label="手机:">{{item.mobile_phone}}</el-descriptions-item>
                      <el-descriptions-item label="上次使用时间:">{{item.use_at}}</el-descriptions-item>
                    </el-descriptions>
                  </el-tab-pane>
                </el-tabs>
              </el-descriptions-item>
              <el-descriptions-item  label="师傅服务区域:">
                <el-tabs  v-model="areaActive">
                  <el-tab-pane v-for="(item,i) in ruleFormcont.worker_area" :key="i" :label="'区域【'+(parseInt(i)+1)+'】'" :name="String((parseInt(i)+1))">
                    <el-descriptions :column="4" >
                      <el-descriptions-item label="区域名称:">{{item.area_name}}</el-descriptions-item>
                      <el-descriptions-item label="区域类型:">
                        <span v-if="item.type==0">核心区域</span>
                        <span v-if="item.type==1">重点区域</span>
                        <span v-if="item.type==2">其余区域</span>
                      </el-descriptions-item>
                    </el-descriptions>
                  </el-tab-pane>
                </el-tabs>
              </el-descriptions-item>
              <el-descriptions-item :span="1" label="师傅技能:">
                <el-tag style="margin: 5px" size="small" v-for="(item,i) in ruleFormcont.worker_service_skill" :key="i">{{item.name}}</el-tag>
              </el-descriptions-item>
            </el-descriptions>
          </el-scrollbar>
        </el-drawer>
        <el-drawer
            title="部门"
            v-model="withdrawer"
            size="30%"
            direction="rtl"
            :close-on-click-modal="false"
            destroy-on-close>
          <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm">
            <el-form-item label="选择部门" prop="group_id">
              <el-cascader
                  placeholder=""
                  ref="cascaderHandle"
                  style="width: 100%"
                  :options="areaArr"
                  v-model="ruleForm.group_id"
                  :props="{
                          label: 'name',
                          value: 'id',
                          children: 'children',
                          checkStrictly: true
                          }"
                  :filterable="true">
              </el-cascader>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">确定</el-button>
            </el-form-item>
          </el-form>
        </el-drawer>
      </el-col>
    </el-row>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/masterApi"//本页面接口
import Withdrawal from '../components/masterChild/Withdrawal'
import Journal from '../components/masterChild/Journal'
import {get,post} from '@/api/Api'
export default {
  name: 'brand',
  components:{
    Withdrawal,
    Journal,
  },
  data(){
    return{
      //部门树形
      ruleList: [],
      isOpen:true,
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'id'
      },//字段定义

      bankActive:'1',
      areaActive:'1',
      activeName:1,
      login:false,
      drawer:false,
      orderdrawer:false,
      //分组
      withdrawer:false,
      areaArr:[],
      //分组
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      group_id:'',
      ruleFormcont:{
      },
      ruleForm:{
        group_id:'',
      },
      rowData:{
        worker_id:'',
      },
      rules:{
        group_id: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.ongroup()
    this.keyupSubmit()
  },

  methods:{

    ongroup(){
      get('','/api/admin/woker_group').then((res)=>{
        this.areaArr=res.data
        this.ruleList=JSON.parse(JSON.stringify(res.data))
        this.ruleList.unshift({
          name:'全部',
          id:''
        })
      }).catch((err)=>{

      })
    },

    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){

          }else {
            post(this.ruleForm,'/api/admin/woker_group/editGroup').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.withdrawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          this.drawer=false
          console.log('error submit!!')
          return false
        }
      })
    },
    upZu(row){
      this.ruleForm={
        id:row.id,
        group_id:row.group_id,
      }
      this.withdrawer=true
    },
    upOrder(row,val){
      let data={}
      let msg=''
      if(val==1){
        msg='请输入接单数'
      }else if(val==2){
        msg='请输入等级'
      }
        this.$prompt(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9]*[1-9][0-9]*$/,
          inputErrorMessage: '请输入大于零的正整数'
        }).then(({ value }) => {
          if(val==1){
            data={
              id:row.id,
              order_count:value,
            }
          }else if(val==2){
            data={
              id:row.id,
              level:value,
            }
          }
          let url='/api/admin/worker/edit'
          post(data,url).then((res)=>{
            this.$message({
              type: 'success',
              message: res.msg
            });
            this.onTable()
          }).catch((err)=>{

          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
    },


    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
        group_id:this.group_id,
      }
      axios.getmasterData(form).then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(val){
      console.log(val)
      if(val){
        this.group_id=val.id
      }
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    upData(row){
      axios.postediview({id:row.id}).then((res)=>{
        if(res.code==200){
          this.ruleFormcont=res.data
          this.drawer=true
        }else {
          this.$message.error(res.msg)
        }
      }).catch((err)=>{
        this.$message.error('服务器报错！！请联系管理员')
      })
    },
    onCashList(row,val){
      this.$confirm(val==1?'师傅直选, 是否继续?':'取消直选, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        post({id:row.id,is_of_us:val},'/api/admin/worker/edit').then((res)=>{
          if(res.code==200){
            this.$message.success(res.msg)
            this.onTable()
          }else {
            this.$message.error(res.msg)
          }
        }).catch((err)=>{
          this.$message.error('服务器报错！！请联系管理员')
        })
      }).catch(() => {
            this.$message({
              type: 'error',
              message: '已取消',
            })
          })

    },

  }
}
</script>
<style lang="less" scoped>
.el-button--mini{
  padding: 7px 5px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>